<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="visible"
    :max-width="800"
    scrim="transparent"
  >
    <v-card
      v-if="enrollment"
      border
      flat
      tile
    >
      <v-card-title>
        <span>{{ enrollmentAriaLabel }}</span>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text
        v-if="providers.length > 0"
        class="py-0"
      >
        <div class="mb-6">
          <label
            class="c-black fs-16 fw-500 mb-2"
            for="provider-select"
          >
            {{ t('What location will they enroll in?') }}
          </label>
          <v-select
            v-model="provider"
            id="provider-select"
            :item-title="(provider) => t(provider.name)"
            :items="providers"
            :readonly="!store.state.pages.Interested.features.enable_provider_transfer"
            item-value="id"
            variant="filled"
            hide-details
            return-object
          />
        </div>

        <div
          v-if="store.state.pages.Interested.features.enable_program_assignment"
          class="mb-6"
        >
          <p class="c-black fs-16 fw-500 mb-2">
            {{ t('What program or class will they enroll in?') }}
          </p>
          <v-select
            v-model="program"
            :aria-label="t('Program')"
            :disabled="!provider"
            :item-title="(program) => t(program.name)"
            :items="programs"
            item-value="id"
            variant="filled"
            hide-details
            return-object
          />
        </div>

        <div v-if="subsidyProgram?.enable_enrollment_funding_sources">
          <p class="c-black fs-16 fw-500 mb-2">
            {{ t('Funding source(s)') }}
          </p>
          <LabeledAutocomplete
            v-model="enrollment.funding_source_ids"
            :items="fundingSources"
            class="funding-sources-autocomplete"
            item-title="name"
            item-value="id"
            chips
            clearable
            deletable-chips
            filled
            multiple
          />
        </div>

        <div
          v-if="subsidyProgram?.enrollment_start_date_required"
          class="mb-6"
        >
          <template v-if="!subsidyProgram.enrollment_start_date_title">
            <p class="c-black fs-16 fw-500 mb-2">
              {{ t('What day do they start?') }}
            </p>
          </template>
          <template v-else>
            <p class="c-black fs-16 fw-500 mb-1">
              {{ t(subsidyProgram.enrollment_start_date_title) }}
            </p>
            <template v-if="subsidyProgram.enrollment_start_date_subtitle">
              <p class="c-light-black fs-16 fw-500 mb-2 pb-3">
                {{ t(subsidyProgram.enrollment_start_date_subtitle) }}
              </p>
            </template>
          </template>

          <v-date-picker
            v-model="startDate"
            :landscape="$vuetify.display.mdAndUp"
            :min="minStartDate()"
            class="b-1 dense bc-primary"
            data-testid="enrollment-startdate-picker"
            full-width
          />
        </div>

        <div
          v-if="store.state.pages.Students?.features?.enable_tuition_amount"
          class="mb-6"
        >
          <v-row>
            <LabeledTextfield
              v-model="rate"
              cols="6"
              message="Payment amount"
              prefix="$"
            />
            <LabeledSelect
              v-model="period"
              :items="['Weekly', 'Bi-weekly', 'Monthly']"
              :multiple="false"
              cols="6"
              message="Payment frequency"
            />
          </v-row>
        </div>
      </v-card-text>

      <v-divider class="mb-3" />

      <v-card-actions>
        <v-btn
          @click="close"
          :ripple="false"
          size="x-large"
          variant="text"
        >
          {{ t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="submit"
          :aria-label="enrollmentAriaLabel"
          :disabled="!provider"
          :loading="processing"
          :ripple="false"
          color="primary"
          size="x-large"
        >
          {{ t(terms.enroll) }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <template v-if="enrollment && subsidyProgram?.enrolled_form_schema_id">
      <FormDialog
        @save="enroll($event)"
        ref="enrolledFormDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.enrolled_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
      />
    </template>
  </v-dialog>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import useTerms from '@/shared/composables/useTerms';

import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';
import { useDate } from 'vuetify';
import { useStore } from 'vuex';

const emit = defineEmits(['enrolled']);

const date = useDate();
const eventBus = useEventBus();
const { t } = useI18n();
const store = useStore();
const { terms } = useTerms();

const enrollment = ref(null);
const enrolledFormDialog = ref(null);
const fundingSources = ref([]);
const visible = ref(false);
const period = ref(null);
const processing = ref(false);
const providers = ref(null);
const program = ref(null);
const programs = ref([]);
const provider = ref(null);
const rate = ref(null);
const startDate = ref(new Date());
const subsidyProgram = ref(null);
const today = ref(new Date());

const enrollmentAriaLabel = computed(() => {
  const translatedTerm = t(terms.value.enroll);
  const childName = enrollment.value.meta.child.name;
  return `${translatedTerm} ${childName}`;
});

function backdatingAllowed() {
  return subsidyProgram.value?.enable_enrollment_start_date_backdating_by_providers;
}

function close() {
  visible.value = false;
}

async function enroll(form) {
  processing.value = true;

  const res = await api.manager.enrollment.update(enrollment.value.id, {
    end_date: null,
    enrolled_form_id: form?.id,
    provider_id: provider.value?.id,
    program_id: program.value?.id,
    start_date: startDate.value ? date.toISO(startDate.value) : null,
    rate: rate.value,
    period: period.value,
    status: 'enrolled',
    funding_source_ids: enrollment.value.funding_source_ids,
  });

  processing.value = false;

  if (res?.status !== 200) return;

  eventBus.chime('Saved');
  visible.value = false;
  emit('enrolled');
}

async function loadPrograms() {
  const { data } = await api.program.index(provider.value?.id);
  programs.value = data.filter(
    (program) =>
      !program.subsidy_program_id || program.subsidy_program_id === subsidyProgram.value.id,
  );
}

function minStartDate() {
  return backdatingAllowed() ? subsidyProgram.value?.enrollment_start_date : today.value;
}

function open({ enrollmentValue, providersValue, subsidyProgramValue }) {
  enrollment.value = enrollmentValue;
  providers.value = providersValue;
  subsidyProgram.value = subsidyProgramValue;
  startDate.value = setDefaultStartDate();
  rate.value = null;
  period.value = null;
  program.value = null;
  provider.value = providers.value.find((provider) => provider.id === enrollment.value.provider_id);
  programs.value = [];
  visible.value = true;
  setFundingSources();
}

function setDefaultStartDate() {
  if (subsidyProgram.value?.enable_enrollment_start_date_backdating_by_providers) {
    let programStartDate = subsidyProgram.value?.enrollment_start_date;
    if (programStartDate) programStartDate = date.parseISO(programStartDate);
    return programStartDate > today.value ? programStartDate : today.value;
  }

  return null;
}

function setFundingSources() {
  if (subsidyProgram.value?.enable_enrollment_funding_sources) {
    api.public_api.organization.funding_source.index(
      {
        owner_id: subsidyProgram.value.id,
        enrollment_assignable: true,
      },
      (response) => {
        fundingSources.value = response.data;
      },
    );
  }
}

function submit() {
  if (subsidyProgram.value?.enrolled_form_schema_id) {
    enrolledFormDialog.value.open();
  } else {
    void enroll();
  }
}

watch(provider, (newVal) => {
  if (newVal) {
    void loadPrograms();
  }
});

defineExpose({ open });
</script>

<style>
/* question: best practice on where to move this? */
.funding-sources-autocomplete {
  margin-bottom: 20px;
  padding: 0;
}
</style>
