<template>
  <div
    class="mb-8"
    data-cy="listing"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-start">
          <v-col
            cols="12"
            md="8"
          >
            <div
              v-text="provider.name"
              class="c-black fs-26 fw-600 mb-4"
            />
            <div class="d-flex align-center fs-16">
              <v-icon
                class="me-2"
                color="primary"
              >
                location_on
              </v-icon>
              {{ location || $t('Location unknown') }}
            </div>
          </v-col>
          <v-col
            v-if="!$store.state.pages.Provider?.features.enable_profile_lock"
            class="d-flex justify-end"
            cols="12"
            md="4"
          >
            <template v-if="meta?.openings.enabled">
              <v-btn
                @click="openAvailabilityDialog()"
                class="me-2"
                color="primary"
                data-cy="update_availability"
                variant="outlined"
              >
                {{ $t('Update availability') }}
              </v-btn>
            </template>
            <v-btn
              id="edit_profile_link"
              :ripple="false"
              :to="{
                name: 'ProviderShow',
                params: {
                  providerId: provider.id,
                },
                query: {
                  tab: 'profile',
                },
              }"
              color="primary"
              variant="flat"
              tracked
            >
              {{ $t('Edit profile') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="interestedDashboardAvailable || enrolledDashboardAvailable">
        <v-divider class="mb-1" />

        <template v-if="interestedDashboardAvailable">
          <v-card-text class="py-2">
            <v-row
              class="d-flex fs-16 mb-1"
              dense
            >
              <div
                v-for="(group, index) in dashboard.enrollment_status_groups"
                :key="group.status"
                :style="`width: ${100 / dashboard.enrollment_status_groups.length}%`"
                class="d-flex"
              >
                <v-col>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      {{ $t(getStatusText(group.status)) }}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center fs-20">
                      <template v-if="group.status == 'Enrolled'">
                        <router-link
                          :to="{
                            name: 'EnrollmentIndex',
                            query: {
                              providerId: provider.id,
                              subsidyProgramId: subsidyProgram?.id,
                            },
                          }"
                        >
                          {{ group.count || 0 }}
                        </router-link>
                      </template>

                      <template v-else>
                        <router-link
                          :to="{
                            name: 'InterestedIndex',
                            query: {
                              providerId: provider.id,
                              status: group.status,
                              subsidyProgramId: subsidyProgram?.id,
                            },
                          }"
                        >
                          {{ group.count || 0 }}
                        </router-link>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider
                  v-if="index < dashboard.enrollment_status_groups.length - 1"
                  :key="`${index}-divider`"
                  vertical
                />
              </div>
            </v-row>
          </v-card-text>
        </template>

        <template v-if="enrolledDashboardAvailable">
          <div
            class="bg-primary-extra-light"
            data-testid="enrolled-dashboard"
          >
            <v-row dense>
              <v-col class="fs-20 fw-800">
                <div class="ps-4 pt-1">
                  {{ $t(terms.programs) }}
                </div>
              </v-col>
            </v-row>

            <v-card-text class="pt-2 pb-4">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col
                  v-for="(group, index) in dashboard.enrollment_program_groups"
                  :key="index"
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <ProgramSeatCount
                    @update:seats="loadDashboard()"
                    :enrollment-program-group="group"
                    :provider-id="provider.id"
                    :seat-types="seatTypes"
                    :subsidy-program="subsidyProgram"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </template>
      </template>

      <template v-if="onboardingEnabled">
        <template v-if="onboardingForm && !!onboardingForm.submitted_at">
          <v-alert
            :title="$t('Completed: ' + $store.state.site.onboarding.title)"
            border="start"
            border-color="green"
            class="mb-0"
            color="surface"
            icon="check_circle"
            type="success"
            prominent
            tile
          >
            <template #append>
              <v-btn
                :append-icon="onboardingForm.locked ? 'lock' : 'lock_open'"
                :to="{
                  name: 'FormNew',
                  params: {
                    providerId: provider.id,
                    schemaId: $store.state.site.onboarding.schema_id,
                  },
                }"
                color="primary"
                variant="text"
              >
                <span class="underlined">{{ $t('Review') }}</span>
              </v-btn>
            </template>
          </v-alert>
        </template>
        <template v-else>
          <v-alert
            :text="$t($store.state.site.onboarding.description)"
            :title="$t($store.state.site.onboarding.title)"
            border="start"
            border-color="primary"
            class="mb-0"
            color="surface"
            icon="notifications"
            type="success"
            prominent
            tile
          >
            <template #append>
              <v-btn
                :to="{
                  name: 'FormNew',
                  params: {
                    providerId: provider.id,
                    schemaId: $store.state.site.onboarding.schema_id,
                  },
                }"
                append-icon="east"
                color="primary"
              >
                {{ $t('Get started') }}
              </v-btn>
            </template>
          </v-alert>
        </template>
      </template>
    </v-card>

    <v-dialog
      v-model="availabilityIsVisible"
      content-class="mx-0"
      max-width="700px"
      scrim="transparent"
    >
      <ProgramOpenings
        @change:openings-confirmed="confirmAvailability($event)"
        @change:openings-count="openingsCount = $event"
        @close="availabilityIsVisible = false"
        ref="openings"
        :openings-confirmed-at="openingsConfirmedAt"
        :programs="programs"
        :provider-id="provider.id"
        :subsidy-program-id="subsidyProgram?.id"
        dialog
      />
    </v-dialog>
  </div>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import ProgramOpenings from '@/shared/components/provider/ProgramOpenings.vue';
import { ProgramParams } from '@/shared/services/program_params';
import ProgramSeatCount from './program/ProgramSeatCount.vue';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import useTerms from '@/shared/composables/useTerms';

const eventBus = useEventBus();
const { getStatusText } = useEnrollmentStatuses();
const store = useStore();
const { terms } = useTerms();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
  seatTypes: {
    type: Array,
    default: () => [],
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const availabilityIsVisible = ref(false);
const dashboard = ref(null);
const meta = ref(store.state.schemas[props.provider.schema_id]?.meta);
const onboardingForm = ref(null);
const openingsCount = ref(0);
const openingsConfirmedAt = ref(props.provider.openings_confirmed_at);
const programs = ref([]);

const enrolledDashboardAvailable = computed(() => {
  if (store.state.pages.Students?.enabled && dashboard.value) {
    return dashboard.value.enrollment_program_groups?.length > 0;
  }
  return false;
});

const interestedDashboardAvailable = computed(() => {
  if (store.state.pages.Interested?.enabled && dashboard.value) {
    return dashboard.value.enrollment_status_groups?.length > 0;
  }
  return false;
});

const location = computed(() => {
  if (!props.provider.address1 || !props.provider.city) return null;
  return `${props.provider.address1.trim()}, ${props.provider.city.trim()}`;
});

const onboardingEnabled = computed(() => {
  return (
    store.state.site.onboarding.enabled &&
    store.state.site.onboarding.provider_schema_id === props.provider.schema_id &&
    props.provider.enable_onboarding
  );
});

onMounted(async () => {
  await Promise.allSettled([loadForms(), loadDashboard()]);
});

watch(() => props.subsidyProgram?.id, loadDashboard);

async function confirmAvailability(message) {
  const resp = await Api.provider.update(props.provider.id, { openings_confirmed: true });

  openingsConfirmedAt.value = resp.data.openings_confirmed_at;
  eventBus.chime(message);
}

async function loadForms() {
  if (onboardingEnabled.value && store.state.site.onboarding.schema_id) {
    const filters = {
      schema_id: store.state.site.onboarding.schema_id,
    };

    const resp = await Api.manager.provider.form.index(props.provider.id, filters);
    [onboardingForm.value] = resp.data;
  }
}

async function loadPrograms() {
  const filters = {
    subsidy_program_id: props.subsidyProgram?.id,
  };
  const { data } = await Api.program.index(props.provider.id, filters);
  programs.value = data.map((program) => new ProgramParams(program).as_draft());
}

async function loadDashboard() {
  const resp = await Api.manager.provider.dashboard.get(
    props.provider.id,
    props.subsidyProgram?.id,
  );
  dashboard.value = resp.data;
}

async function openAvailabilityDialog() {
  await loadPrograms();
  availabilityIsVisible.value = true;
}
</script>
