<template>
  <div v-if="loaded">
    <v-container class="mxw-800 mx-auto py-12 px-4">
      <template v-if="section === 'finish'">
        <div class="px-4">
          <h1 class="mb-4">
            {{ $t(quizSchema.name) }}
          </h1>

          <div class="d-flex align-start mb-4">
            <div>
              {{ $t(gradeScaleDisplayText) }}
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="mx-auto mb-6 ta-center">
          <div class="fs-22 fw-500 mb-0">
            {{ $t(quizSchema.name) }}
          </div>

          <div class="fs-16 c-light-black">
            <span class="me-1">
              {{ $t('Question') }}
            </span>

            <span>
              {{ stepCount >= stepTotal ? stepTotal - 1 : stepCount }} / {{ stepTotal - 1 }}
            </span>
          </div>
        </div>

        <div
          ref="progress"
          :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
          class="px-4 focus-invisible"
          tabindex="0"
          aria-live
        >
          <v-progress-linear
            v-model="progress"
            :indeterminate="!loaded"
            class="focus-invisible mb-4"
            color="primary"
          />
        </div>
      </template>

      <div class="px-4">
        <QuestionSet
          v-model="quiz"
          @back="backFromQuestion($event)"
          @next="forwardFromQuestion($event)"
          :processing="processing"
          :questions="validQuestions"
          :schema="quizSchema.definition"
          :section="section"
          :transition-name="transitionName"
          color="white"
          key-name="question"
          border
        />

        <template v-if="section == 'finish'">
          <QuestionSet
            v-model="quiz"
            :questions="validQuestions"
            :schema="quizSchema.definition"
            color="white"
            key-name="question"
            border
            condensed
            dense
            display-answer
            expanded
            hide-actions
            readonly
            show-passing
          />

          <v-row>
            <v-col class="d-flex justify-space-between">
              <v-btn
                v-if="!quiz.passing"
                @click="retake"
                color="primary"
                size="x-large"
              >
                <span>
                  {{ $t('Retake quiz') }}
                </span>
              </v-btn>

              <v-btn
                :to="{ name: 'CourseShow', params: { courseId: lessonCompletion.course_id } }"
                color="primary"
                size="x-large"
              >
                <span>
                  {{ $t('Return to course') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script setup>
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import useStepper from '@/shared/composables/useStepper';
import Api from '@/shared/services/all_bright_finder';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';

const { updateQuery } = useRouterHelper();

const route = useRoute();
const router = useRouter();

const gradeScaleDisplayText = ref(null);
const lesson = ref(null);
const lessonCompletion = ref(null);
const quiz = ref(null);
const quizSchema = ref(null);
const processing = ref(false);
const ownerIdRef = ref(route.params.quizSchemaId);

const { progress, section, stepBack, stepForward, stepCount, stepTotal, transitionName } =
  useStepper({
    processing,
    route,
    updateQuery,
  });

const { questionsLoaded, validQuestions, validateAnswers } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef,
  syncedObjectRef: quiz,
});

const loaded = computed(() => {
  return lessonCompletion.value && quizSchema.value && quiz.value && questionsLoaded.value;
});

watch(
  loaded,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      stepCount.value = parseInt(route.query.step, 10);
      if (!stepTotal.value) await validateAnswers();

      recalculateStepTotal();
      if (route.query.section) {
        setTimeout(() => {
          section.value = route.query.section;
          progress.value.focus();
          progress.value = (stepCount.value / stepTotal.value) * 100;
        }, 500);
      } else {
        router.push({ query: { section: 'question-0', step: 1 } });
      }
    }
  },
  { immediate: true },
);

watch(
  () => route.query.section,
  async (newValue) => {
    section.value = null;
    stepCount.value = parseInt(route.query.step, 10);
    if (!stepTotal.value) {
      await validateAnswers();
    }
    recalculateStepTotal();

    setTimeout(() => {
      if (newValue) {
        section.value = newValue;
        progress.value.focus();
      } else {
        section.value = 'question-0';
      }
      progress.value = (stepCount.value / stepTotal.value) * 100;
    }, 600);
  },
);

onMounted(load);

async function backFromQuestion(index) {
  processing.value = true;
  await saveQuiz();
  await validateAnswers();
  recalculateStepTotal();

  if (index - 1 < 0) {
    router.push({
      name: 'CourseShow',
      params: { courseId: lessonCompletion.value.course_id },
    });
  } else {
    stepBack(`question-${index - 1}`);
  }
}

function createQuiz() {
  Api.manager.quiz.create(
    {
      lesson_completion_id: route.params.lessonCompletionId,
      schema_id: route.params.quizSchemaId,
    },
    (response) => {
      quiz.value = response.data;
    },
  );
}

async function finish() {
  quiz.value.submitted = true;
  await saveQuiz();
  gradeScaleDisplayText.value = getGradeScaleDisplayText();
  stepForward('finish');
}

async function forwardFromQuestion(index) {
  processing.value = true;

  if (index + 1 >= validQuestions.value.length) {
    await finish();
  } else {
    quiz.value.submitted = false;
    await saveQuiz();
    await validateAnswers();
    recalculateStepTotal();
    stepForward(`question-${index + 1}`);
  }
}

function getGradeScaleDisplayText() {
  const sortedGradeScales = lesson.value.grade_scale.sort((gradeScale1, gradeScale2) =>
    gradeScale1.min_score > gradeScale2.min_score ? -1 : 1,
  );

  const grade =
    sortedGradeScales.find((gradeScale) => quiz.value.score >= gradeScale.min_score) ||
    sortedGradeScales.at(-1);

  return grade.display_text;
}

async function load() {
  loadQuizSchema();
  await loadLessonCompletion();
  await loadLesson();
  createQuiz();
}

async function loadLesson() {
  const response = await Api.public_api.organization.lesson.get(lessonCompletion.value.lesson_id);
  lesson.value = response.data;
}

async function loadLessonCompletion() {
  const response = await Api.lesson_completion.get(route.params.lessonCompletionId);
  lessonCompletion.value = response.data;
}

function loadQuizSchema() {
  Api.public_api.organization.schema.get(route.params.quizSchemaId, (response) => {
    quizSchema.value = response.data;
  });
}

function recalculateStepTotal() {
  stepTotal.value = validQuestions.value.length + 1;
  progress.value = (stepCount.value / stepTotal.value) * 100;
}

async function retake() {
  quiz.value = null;
  router.push({
    name: 'LessonQuiz',
    params: {
      lessonCompletionId: lessonCompletion.value.id,
      quizSchemaId: route.params.quizSchemaId,
    },
    query: { retake: true },
  });
  stepCount.value = 0;
  await load();
}

async function saveQuiz() {
  const { data } = await Api.manager.quiz.promiseUpdate(quiz.value.id, quiz.value);
  quiz.value = data;
}
</script>
