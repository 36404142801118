<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-8"
    >
      <v-row
        v-if="grant"
        class="mb-3 ps-4"
      >
        <v-col>
          <router-link
            :to="{ name: 'GrantProgramShow', params: { grantProgramId: grant.grant_program_id } }"
            class="td-none"
            color="primary"
          >
            <v-icon
              color="primary"
              icon="west"
              start
            />
            <span>
              {{ $t('Back to grant') }}
            </span>
          </router-link>
        </v-col>
      </v-row>

      <div
        ref="progressRef"
        :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
        class="px-4 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!loaded"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>

      <div class="px-4">
        <QuestionSet
          v-model="grant"
          @back="backFromEligibility($event)"
          @next="forwardFromEligibility($event)"
          :header="grantProgram.name"
          :processing="processing"
          :questions="validEligibilityQuestions"
          :readonly="grant.locked"
          :schema="grantSchema.definition"
          :section="section"
          :transition-name="transitionName"
          key-name="eligibility"
        />
        <QuestionSet
          v-model="grant"
          @back="backFromQuestion($event)"
          @next="forwardFromQuestion($event)"
          :header="grantProgram.name"
          :processing="processing"
          :questions="validQuestions"
          :readonly="grant.locked"
          :schema="grantSchema.definition"
          :section="section"
          :transition-name="transitionName"
          key-name="question"
        />

        <transition-group :name="transitionName">
          <div
            v-for="(question, index) in validVerificationQuestions"
            v-show="section == ['verification', index].join('-')"
            :key="['verification', index].join('-')"
          >
            <FormQuestion
              @back="backFromVerificationQuestion(index)"
              @next="forwardFromVerificationQuestion(index)"
              :header="grantProgram.name"
              :next-disabled="isNextDisabled(question)"
              :processing="processing"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  business_id: grant.business_id,
                  type: 'Grant',
                  id: grant.id,
                  tag: question.id,
                  tags: [grant.id, question.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                :processing="processing"
                class="mb-6"
              />
            </FormQuestion>
          </div>
        </transition-group>

        <transition-group :name="transitionName">
          <FormQuestion
            v-show="section == 'finish'"
            @back="backFromFinish()"
            @finish="finishAndContinue"
            key="finish"
            :finish-disabled="finishDisabled"
            :finish-text="grant.locked ? 'Return to grants' : 'Confirm and submit'"
            :forwardable="false"
            :processing="processing"
            :title="grant.locked ? 'Finish review' : 'Confirm and submit'"
            section="Finish"
            finishable
          >
            <p v-if="grant.locked">
              {{ $t(SUBMITTED_MESSAGE) }}
            </p>

            <UserAttestation
              v-else
              @change="confirmed = $event"
              ref="userAttestation"
              :attestation-label="grantProgram.attestation_label"
              :attestation-text="grantProgram.attestation_text"
              :enable-esign="grantProgram.enable_esign"
              :program="grantProgram"
            />
          </FormQuestion>
        </transition-group>
      </div>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useGrant from '@/shared/composables/useGrant';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useStepper from '@/shared/composables/useStepper';
import { useStore } from 'vuex';

const SUBMITTED_MESSAGE =
  'Thanks for completing your application. Your application has already been submitted.';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { updateQuery } = useRouterHelper();

const attachments = ref([]);
const confirmed = ref(false); // TODO: Rename this variable
const grant = ref(null);
const grantProgram = ref(null);
const grantSchema = ref(null);
const processing = ref(false);
const progressRef = ref(null);
const userAttestation = ref(null);

const {
  loadQuestions,
  questionsLoaded,
  validateAnswers,
  validEligibilityQuestions,
  validQuestions,
  validVerificationQuestions,
} = useGrant({ grantRef: grant });

const { progress, section, stepBack, stepForward, stepCount, stepTotal, transitionName } =
  useStepper({
    processing,
    route,
    updateQuery,
  });

const finishDisabled = computed(() => {
  return grant.value.locked ? false : !confirmed.value;
});

const loaded = computed(() => {
  return grant.value && grantSchema.value && grantProgram.value && questionsLoaded.value;
});

watch(
  () => loaded.value,
  async (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      // Step count update
      if (route.query.step) stepCount.value = parseInt(route.query.step, 10);
      else stepCount.value = 0;

      recalculateStepTotal();

      if (route.query.section) {
        section.value = route.query.section;
        progressRef.value?.focus();
        progress.value = (stepCount.value / stepTotal.value) * 100;
      } else {
        start();
      }
    }
  },
  { immediate: true },
);

watch(
  () => questionsLoaded.value,
  (newVal) => {
    if (newVal) recalculateStepTotal();
  },
);

watch(
  () => route.query.section,
  async (newVal) => {
    section.value = null;
    stepCount.value = parseInt(route.query.step, 10);

    recalculateStepTotal();

    if (newVal) {
      section.value = newVal;
      progressRef.value?.focus();
    } else {
      section.value = 'question-0';
    }
    progress.value = (stepCount.value / stepTotal.value) * 100;
  },
);

onMounted(() => load());

function isNextDisabled(question) {
  return (
    question.verification_mandatory &&
    attachments.value.filter((attachment) => attachment.tag === question.id).length === 0
  );
}

async function load() {
  await loadGrant();

  grantSchema.value = store.state.schemas[grant.value.schema_id];

  void loadGrantProgram();
  await loadQuestions();
  await loadAttachments();
}

async function loadAttachments() {
  processing.value = true;
  const params = {
    owner_type: 'Grant',
    owner_id: grant.value.id,
  };
  const response = await Api.member.attachment.index(params);
  if (response?.status !== 200) {
    processing.value = false;
    return;
  }

  attachments.value = response.data;
  processing.value = false;
  return;
}

async function loadGrant() {
  const { data } = await Api.manager.grant.get(route.params.grantId);
  grant.value = data;
}

async function loadGrantProgram() {
  const { data } = await Api.public_api.organization.grant_program.get(
    grant.value.grant_program_id,
  );
  grantProgram.value = data;
}

async function saveAndValidate() {
  processing.value = true;
  if (grant.value.locked) return;

  await saveGrant();
  await validateAnswers();
  recalculateStepTotal();
}

async function saveGrant() {
  const response = await Api.manager.grant.update(grant.value.id, grant.value);
  if (response) grant.value = response.data;
}

function goToGrantProgramShow() {
  router.push({
    name: 'GrantProgramShow',
    params: { grantProgramId: grant.value.grant_program_id },
  });
}

// Stepping interactions
async function backFromFinish() {
  await saveAndValidate();

  if (validVerificationQuestions.value.length > 0) {
    stepBack(`verification-${validVerificationQuestions.value.length - 1}`);
  } else {
    stepBack(`question-${validQuestions.value.length - 1}`);
  }
}

async function backFromEligibility(index) {
  if (index - 1 < 0) {
    goToGrantProgramShow();
  } else {
    stepBack(`eligibility-${index - 1}`);
  }
}

async function backFromQuestion(index) {
  await saveAndValidate();

  if (index - 1 < 0) {
    if (validEligibilityQuestions.value.length > 0) {
      stepBack(`eligibility-${validEligibilityQuestions.value.length - 1}`);
    } else {
      goToGrantProgramShow();
    }
  } else {
    stepBack(`question-${index - 1}`);
  }
}

async function backFromVerificationQuestion(index) {
  processing.value = true;
  if (index - 1 < 0) {
    void backFromQuestion(validQuestions.value.length);
  } else {
    stepBack(`verification-${index - 1}`);
  }
}

async function finishAndContinue() {
  processing.value = true;

  if (!!grant.value.submitted_at || grant.value.locked) return goToGrantProgramShow();

  const submitParams = {};
  if (grantProgram.value.enable_esign) {
    submitParams.revision_author_signed_name = userAttestation.value?.esignName;
    submitParams.revision_author_signature_image = userAttestation.value?.getEsignSignature();
  }

  const attestationText = userAttestation.value?.getAttestationText();
  if (attestationText) submitParams.revision_description = attestationText;

  const { status } = await Api.manager.grant.submit(grant.value.id, submitParams);
  if (status !== 200) return (processing.value = false);

  eventBus.chime('Application submitted');
  goToGrantProgramShow();
}

async function forwardFromEligibility(index) {
  await saveAndValidate();

  if (index + 1 >= validEligibilityQuestions.value.length) {
    void forwardFromQuestion(-1);
  } else {
    stepForward(`eligibility-${index + 1}`);
  }
}

async function forwardFromQuestion(index) {
  await saveAndValidate();

  if (validQuestions.value.length === 0 || index + 1 >= validQuestions.value.length) {
    if (validVerificationQuestions.value.length > 0) {
      stepForward('verification-0');
    } else {
      stepForward('finish');
    }
  } else {
    stepForward(`question-${index + 1}`);
  }
}

function forwardFromVerificationQuestion(index) {
  processing.value = true;

  if (index + 1 >= validVerificationQuestions.value.length) {
    stepForward('finish');
  } else {
    stepForward(`verification-${index + 1}`);
  }
}

function recalculateStepTotal() {
  stepTotal.value =
    validEligibilityQuestions.value.length +
    validQuestions.value.length +
    validVerificationQuestions.value.length +
    1;
  progress.value = (stepCount.value / stepTotal.value) * 100;
}

function start() {
  processing.value = true;

  if (validEligibilityQuestions.value.length === 0) {
    if (validQuestions.value.length > 0) {
      stepForward('question-0');
    } else if (validVerificationQuestions.value.length > 0) {
      stepForward('verification-0');
    } else {
      stepForward('finish');
    }
  } else {
    stepForward('eligibility-0');
  }
}
</script>
