<template>
  <ContentDialog
    ref="contentDialog"
    :max-width="1000"
    :title="form?.name || undefined"
    closeable
  >
    <template
      v-if="form && schema"
      #content
    >
      <QuestionSet
        v-model="form"
        :attachments="attachments"
        :questions="validPublishedQuestions"
        :schema="schema.definition"
        key-name="question"
        attachment-readonly
        expanded
        hide-actions
        readonly
      />
    </template>
  </ContentDialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import { useStore } from 'vuex';

defineExpose({ open });

const attachments = ref([]);
const form = ref(null);
const schema = ref(null);
const store = useStore();

// Template refs
const contentDialog = ref(null);

const schemaId = computed(() => schema.value?.id);

const { validPublishedQuestions } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: schemaId,
  syncedObjectRef: form,
});

async function loadAttachments() {
  const params = { form_id: form.value.id };
  const { data } = await Api.manager.attachment.index(params);
  attachments.value = data;
}

async function open(newForm) {
  form.value = newForm;
  schema.value = store.state.schemas[form.value.schema_id];
  await loadAttachments();
  contentDialog.value?.open();
}
</script>
