<template>
  <template v-if="acceptFormVisible || enrollmentFormVisible">
    <template v-if="enrollmentFormVisible">
      <FormCard
        v-if="subsidyProgram?.enrolled_form_schema_id"
        @open="reviewDialog.open(enrolledForm)"
        @start="enrolledFormDialog.open()"
        :form="enrolledForm"
        :schema-id="subsidyProgram.enrolled_form_schema_id"
      />

      <FormDialog
        @save="emit('change:enrolled-form', $event)"
        ref="enrolledFormDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.enrolled_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
      />
    </template>

    <FormCard
      v-if="acceptFormVisible"
      @open="reviewDialog.open(acceptForm)"
      :form="acceptForm"
      :schema-id="subsidyProgram.accept_form_schema_id"
    />

    <FormReviewDialog ref="reviewDialog" />
  </template>
</template>

<script setup>
import FormDialog from '@/shared/components/form/FormDialog.vue';
import FormCard from '@/manager/components/FormCard.vue';
import FormReviewDialog from '@/manager/components/FormReviewDialog.vue';

const props = defineProps({
  acceptForm: {
    type: Object,
    default: null,
  },
  enrollment: {
    type: Object,
    default: null,
  },
  enrolledForm: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const enrolledFormDialog = ref(null);
const reviewDialog = ref(null);

const emit = defineEmits(['change:enrolled-form']);

const acceptFormVisible = computed(() => {
  return props.subsidyProgram?.accept_form_schema_id && props.acceptForm;
});

const enrollmentFormVisible = computed(() => {
  return props.enrollment && props.subsidyProgram?.enrolled_form_schema_id;
});
</script>
