<template>
  <div>
    <v-dialog
      v-model="visible"
      :max-width="1000"
      :scrim="false"
    >
      <v-card
        v-if="enrollment"
        border
        flat
        tile
      >
        <EnrollmentDialogTitle
          @close="close"
          @edit:funding-sources="editFundingSources"
          @edit:program="$refs.programDialog.open()"
          :enrollment="enrollment"
          :funding-sources="fundingSources"
          :subsidy-program="subsidyProgram"
        />

        <v-card-text class="px-0 mt-4">
          <v-divider />

          <v-tabs
            v-model="tab"
            grow
          >
            <v-tab
              v-for="tabTitle in tabs"
              :key="tabTitle"
              :tab-value="tabTitle"
              class="c-black fs-16 ls-normal tt-none"
            >
              {{ $t(tabTitle) }}
            </v-tab>
          </v-tabs>

          <v-divider />

          <v-window
            v-model="tab"
            class="px-6 py-4"
          >
            <v-window-item value="Family">
              <EnrollmentDialogFamilyTab
                :enrollment="enrollment"
                :subsidy-program="subsidyProgram"
              />
            </v-window-item>
            <v-window-item value="Notes">
              <EnrollmentDialogNotesTab
                @save="saveNotes"
                :notes="enrollment.notes"
              />
            </v-window-item>
            <v-window-item
              v-if="showFormsTab"
              value="Forms"
            >
              <EnrollmentDialogFormsTab
                @change:enrolled-form="saveEnrolledForm"
                :accept-form="acceptForm"
                :enrolled-form="enrolledForm"
                :enrollment="enrollment"
                :subsidy-program="subsidyProgram"
              />
            </v-window-item>
            <v-window-item value="Documents">
              <EnrollmentDialogDocumentsTab
                :enrollment="enrollment"
                :subsidy-program="subsidyProgram"
              />
            </v-window-item>
          </v-window>

          <v-divider class="mt-6" />

          <v-btn
            @click="checkEndDate()"
            class="mt-4"
            color="red"
            variant="text"
            block
          >
            {{ $t(defaultUnenrolledAction) }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ResourceDialog
      v-if="enrollment"
      @save="saveProgram"
      ref="programDialog"
      :title="terms.program"
      close-on-save
      closeable
    >
      <template #form>
        <v-row>
          <LabeledSelect
            v-model="enrollment.program_id"
            :items="programs"
            :message="`What program or class will they ${terms.enroll.toLowerCase()} in?`"
            :multiple="false"
            cols="12"
            item-title="name"
            item-value="id"
          />
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="saveFundingSources"
      ref="editFundingSourceDialog"
      :fields="fundingSourceFields"
      title="Edit funding sources"
    />

    <ResourceDialog
      @save="unenrollWithoutEndDate"
      ref="noEndDateUnenrollDialog"
      :max-width="800"
      title="Confirm unenrollment"
      close-on-save
    >
      <template #form>
        <v-row dense>
          <v-col>
            <p class="fs-16 mb-0">
              {{
                $t(
                  "By unenrolling before the start date, you're confirming the child never attended.",
                )
              }}
            </p>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="draftUnenroll"
      ref="endDateDialog"
      :max-width="800"
      title="Enter the last day the child attended the program"
      close-on-save
    >
      <template #form>
        <v-date-picker
          v-model="enrollmentEndDate"
          :landscape="$vuetify.display.mdAndUp"
          :max="maximumEnrollmentEndDate"
          :min="minimumEnrollmentEndDate"
          class="b-1 dense bc-primary"
          data-testid="enrollment-end-date-picker"
          full-width
        />
      </template>
    </ResourceDialog>

    <FormDialog
      v-if="subsidyProgram && subsidyProgram.unenroll_form_schema_id && enrollment"
      @save="unenroll"
      ref="unenrollDialog"
      :enrollment-id="enrollment.id"
      :group-id="enrollment.group_id"
      :provider-id="enrollment.provider_id"
      :schema-id="subsidyProgram.unenroll_form_schema_id"
      :subsidy-id="enrollment.subsidy_id"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import EnrollmentDialogDocumentsTab from '@/manager/components/enrollment/EnrollmentDialogDocumentsTab.vue';
import EnrollmentDialogNotesTab from '@/manager/components/enrollment/EnrollmentDialogNotesTab.vue';
import EnrollmentDialogFormsTab from '@/manager/components/enrollment/EnrollmentDialogFormsTab.vue';
import EnrollmentDialogFamilyTab from '@/manager/components/enrollment/EnrollmentDialogFamilyTab.vue';
import EnrollmentDialogTitle from '@/manager/components/enrollment/EnrollmentDialogTitle.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import useTerms from '@/shared/composables/useTerms';
import { useDate } from 'vuetify';

const vuetifyDate = useDate();
const { getStatusText } = useEnrollmentStatuses();
const { terms } = useTerms();

const props = defineProps({
  subsidyPrograms: {
    type: Array,
    default: () => [],
  },
  testHarness: {
    type: Object,
    default: undefined,
  },
});

defineExpose({ open });
const emit = defineEmits(['change']);

const acceptForm = ref(null);
const enrolledForm = ref(null);
const enrollment = ref(null);
const enrollmentEndDate = ref(null);
const fundingSources = ref([]);
const maximumEnrollmentEndDate = ref(null);
const minimumEnrollmentEndDate = ref(null);
const programs = ref([]);
const processing = ref(false);
const subsidyProgram = ref(null);
const tab = ref(0);
const visible = ref(false);

const confirmDialog = ref(null);
const editFundingSourceDialog = ref(null);
const endDateDialog = ref(null);
const unenrollDialog = ref(null);
const programDialog = ref(null);
const noEndDateUnenrollDialog = ref(null);

const defaultUnenrolledAction = computed(() => {
  return subsidyProgram.value?.default_unenrolled_action || terms.value.unenroll;
});

const defaultUnenrolledStatus = computed(() => {
  return (
    subsidyProgram.value?.default_unenrolled_status || getStatusText(ENROLLMENT_STATUSES.UNENROLLED)
  );
});

const fundingSourceFields = computed(() => {
  return [
    {
      text: 'Funding sources',
      value: 'funding_source_ids',
      itemText: 'name',
      itemValue: 'id',
      items: fundingSources.value,
      multiple: true,
    },
  ];
});

const showFormsTab = computed(() => {
  return (
    subsidyProgram.value?.enrolled_form_schema_id ||
    (subsidyProgram.value?.accept_form_schema_id && enrollment.value?.accept_form_id)
  );
});

const tabs = computed(() => {
  const tabs = ['Family', 'Notes'];
  if (showFormsTab.value) tabs.push('Forms');
  tabs.push('Documents');

  return tabs;
});

const today = computed(() => {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
});

function checkEndDate() {
  if (
    minimumEnrollmentEndDate.value &&
    maximumEnrollmentEndDate.value &&
    minimumEnrollmentEndDate.value > maximumEnrollmentEndDate.value
  ) {
    noEndDateUnenrollDialog.value?.open();
  } else {
    endDateDialog.value?.open();
  }
}

async function draftUnenroll() {
  const confirmText = `Are you certain you want to ${defaultUnenrolledAction.value.toUpperCase()} this student?`;

  if (!(await confirmDialog.value.confirmWithText(confirmText))) return;

  if (subsidyProgram.value?.unenroll_form_schema_id) {
    unenrollDialog.value?.open();
  } else {
    void unenroll();
  }
}

function editFundingSources() {
  editFundingSourceDialog.value?.open(JSON.parse(JSON.stringify(enrollment.value)));
}

async function saveFundingSources(newVal) {
  processing.value = true;

  const params = {
    funding_source_ids: newVal.funding_source_ids,
  };
  const response = await Api.manager.enrollment.update(enrollment.value.id, params);
  if (response?.status !== 200) {
    processing.value = false;
    return false;
  }

  enrollment.value = response.data;
  emit('change');
  editFundingSourceDialog.value?.close();
  processing.value = false;

  return true;
}

function getMinimumEndDate() {
  if (!enrollment.value?.start_date) return undefined;
  return vuetifyDate.parseISO(enrollment.value.start_date);
}

function close() {
  programDialog.value.close();
  visible.value = false;
}

async function loadAcceptForm() {
  acceptForm.value = (
    await Api.manager.provider.form.get(
      enrollment.value.provider_id,
      enrollment.value.accept_form_id,
    )
  ).data;
}

async function loadEnrolledForm() {
  enrolledForm.value = (
    await Api.manager.provider.form.get(
      enrollment.value.provider_id,
      enrollment.value.enrolled_form_id,
    )
  ).data;
}

async function loadPrograms() {
  const filters = { subsidy_program_id: subsidyProgram.value?.id };
  const { data } = await Api.program.index(enrollment.value.provider_id, filters);
  programs.value = data;
}

async function open(enrollmentRecord) {
  tab.value = 0;
  programs.value = [];
  acceptForm.value = null;
  enrolledForm.value = null;
  enrollment.value = enrollmentRecord;
  if (enrollment.value.enrolled_form_id) void loadEnrolledForm();
  if (enrollment.value.accept_form_id) void loadAcceptForm();

  subsidyProgram.value = props.subsidyPrograms.find(
    (subProgram) => subProgram.id === enrollment.value.subsidy_program_id,
  );

  enrollmentEndDate.value = today.value;
  maximumEnrollmentEndDate.value = today.value;
  minimumEnrollmentEndDate.value = getMinimumEndDate();

  visible.value = true;
  void loadPrograms();

  if (!subsidyProgram.value?.enable_enrollment_funding_sources) return;

  Api.public_api.organization.funding_source.index(
    {
      owner_id: subsidyProgram.value.id,
      enrollment_assignable: true,
    },
    (response) => {
      fundingSources.value = response.data;
    },
  );
}

async function saveEnrolledForm(newForm) {
  const response = await Api.manager.enrollment.update(enrollment.value.id, {
    enrolled_form_id: newForm.id,
  });
  if (response?.status !== 200) return;

  enrollment.value = response.data;
  void loadEnrolledForm();
  emit('change');
}

async function saveNotes(newVal) {
  if (enrollment.value) {
    await Api.manager.enrollment.update(enrollment.value.id, { notes: newVal });
    enrollment.value.notes = newVal;
  } else {
    console.error('Failed to update');
  }
}

async function saveProgram() {
  processing.value = true;
  const resp = await Api.manager.enrollment.update(enrollment.value.id, {
    program_id: enrollment.value.program_id,
  });
  processing.value = false;
  if (resp?.status !== 200) return;

  emit('change');
  enrollment.value = resp.data;
}

async function unenroll() {
  const params = {
    status: defaultUnenrolledStatus.value,
    end_date: enrollmentEndDate.value ? vuetifyDate.toISO(enrollmentEndDate.value) : null,
  };

  const response = await Api.manager.enrollment.update(enrollment.value.id, params);
  if (response?.status !== 200) return;

  close();
  emit('change');
}

async function unenrollWithoutEndDate() {
  if (subsidyProgram.value?.unenroll_form_schema_id) {
    enrollmentEndDate.value = null;
    unenrollDialog.value?.open();
  } else {
    const params = {
      status: defaultUnenrolledStatus.value,
      end_date: null,
    };

    const response = await Api.manager.enrollment.update(enrollment.value.id, params);
    if (response?.status !== 200) return;
  }

  close();
  emit('change');
}

onMounted(async () => {
  if (!props.testHarness) return;
  await open(props.testHarness.enrollment);
});
</script>
