<template>
  <v-row>
    <LabeledTextarea
      v-model="draftNotes"
      message="Notes"
      rows="3"
    />
  </v-row>

  <v-row>
    <v-col>
      <v-btn
        @click="processSave"
        color="primary"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup>
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';

const props = defineProps({
  notes: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['save']);

const draftNotes = ref(props.notes);

watch(
  () => props.notes,
  (newVal) => {
    draftNotes.value = newVal;
  },
);

function processSave() {
  emit('save', draftNotes.value);
}
</script>
