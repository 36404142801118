<template>
  <v-row class="my-1">
    <LabeledTextfield
      :model-value="enrollment.meta.group.primary_first_name"
      cols="12"
      md="6"
      message="Primary first name"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.primary_last_name"
      cols="12"
      md="6"
      message="Primary last name"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.primary_phone"
      cols="12"
      md="6"
      message="Primary phone"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.primary_email"
      cols="12"
      md="6"
      message="Primary email"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.secondary_first_name"
      cols="12"
      md="6"
      message="Secondary first name"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.secondary_last_name"
      cols="12"
      md="6"
      message="Secondary last name"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.secondary_phone"
      cols="12"
      md="6"
      message="Secondary phone"
      dense
      readonly
    />
    <LabeledTextfield
      :model-value="enrollment.meta.group.secondary_email"
      cols="12"
      md="6"
      message="Secondary email"
      dense
      readonly
    />
  </v-row>
  <template v-if="subsidyProgram?.enable_provider_review_viewable_fields">
    <ViewOnlyDataFields
      :excluded-props="displayedFields"
      :schema-id="enrollment.meta.group.schema_id"
      :value-object="enrollment.meta.view_only_group"
      dense
    />
  </template>
</template>

<script setup>
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ViewOnlyDataFields from '@/shared/components/ViewOnlyDataFields.vue';

const DISPLAYED_FIELDS = [
  'created_at',
  'external_id',
  'id',
  'name',
  'primary_email',
  'primary_first_name',
  'primary_last_name',
  'primary_phone',
  'schema_id',
  'secondary_email',
  'secondary_first_name',
  'secondary_last_name',
  'secondary_phone',
  'updated_at',
];

defineProps({
  enrollment: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const displayedFields = ref(DISPLAYED_FIELDS);
</script>
