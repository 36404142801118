<template>
  <AttachmentUploader
    @uploaded="loadAttachments"
    ref="enrollment_attachment_uploader"
    :owner="{
      type: 'Business',
      id: store.state.profile.business_id,
      child_id: enrollment.child_id,
    }"
    class="mb-4"
  />

  <AttachmentList
    v-if="attachments.length > 0"
    @delete="loadAttachments"
    :attachments="attachments"
  />
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  enrollment: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const attachments = ref([]);

onMounted(async () => {
  await loadAttachments();
});

async function loadAttachments() {
  const params = {
    child_id: props.enrollment.child_id,
    subsidy_program_id: props.subsidyProgram?.id,
  };
  const response = await api.manager.attachment.index(params);
  if (response?.status !== 200) return;

  attachments.value = response.data;
}
</script>
