<template>
  <v-card
    class="ma-4"
    flat
    tile
  >
    <v-card
      border
      flat
      tile
    >
      <v-alert
        v-if="note"
        class="fs-16 fw-500 mb-0"
        color="primary"
        type="info"
        closable
        tile
      >
        <span>
          <strong> {{ $t('Note') }}: </strong>

          {{ $t(note) }}
        </span>
      </v-alert>

      <v-card-text>
        <v-row
          class="d-flex"
          dense
        >
          <LabeledAutocomplete
            v-model="selectedProviderId"
            :cols="3"
            :disabled="processing"
            :items="providers"
            :placeholder="$t('Select provider')"
            item-title="name"
            item-value="id"
            clearable
            dense
          />
          <LabeledTextfield
            v-model="search"
            :cols="3"
            :placeholder="$t('Search')"
            class="mt-1"
            prepend-inner-icon="search"
            clearable
            dense
          />
          <v-spacer />
          <v-btn
            @click="newStaffMember()"
            class="mt-1"
            color="primary"
          >
            {{ $t('New staff member') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-text class="pa-0">
      <v-data-table
        v-model="selectedMember"
        @click:row="editStaffMember"
        :headers="tableHeaders"
        :items="employees"
        :loading="processing"
        :search="search"
        class="b-radius-0 b-1 bc-outlined-gray"
        item-key="id"
      >
        <template #item.name="{ item }">
          <div v-if="item.business_role == 'Admin'">
            <v-icon size="small"> admin_panel_settings </v-icon> {{ item.name }}
          </div>
          <div v-else><v-icon size="small"> person </v-icon> {{ item.name }}</div>
        </template>

        <template #item.title="{ item }">
          {{ item.employments?.[0]?.title }}
        </template>

        <template #item.locations="{ item }">
          <v-chip
            v-if="item.provider_ids.length === 0"
            size="x-small"
            variant="outlined"
          >
            {{ $t('All locations') }}
          </v-chip>
          <v-chip-group mandatory>
            <template v-for="p in providers">
              <v-chip
                v-if="item.provider_ids.includes(p.id)"
                :key="p.id"
                :text="p.name"
                :value="p.id"
                size="x-small"
                variant="outlined"
              />
            </template>
          </v-chip-group>
        </template>

        <template #item.background_check_status="{ item }">
          <span v-if="item.background_check_status == 'Clear'">
            <v-icon size="small"> verified_user </v-icon> Clear
          </span>
          <span v-else> <v-icon size="small"> check_circle </v-icon> Enrolled </span>
        </template>
        <template #item.employment_status="{ item }">
          <v-icon size="small">
            {{ item.employment_status == 'Dismissed' ? 'person_off' : 'person' }}
          </v-icon>
          {{ item.employment_status }}
        </template>

        <template #item.start_date="{ item }">
          <LongDate
            :date="item.employments?.[0]?.start_date || item.created_at"
            short-month
          />
        </template>

        <template #item.updated_at="{ item }">
          <LongDate
            :date="item.updated_at"
            short-month
          />
        </template>
        <!-- <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:dismiss="dismiss(item)"
              @click:action:reactivate="reactivateEmployee(item)"
              :items="getEmployeeActions(item)"
              button-icon="more_vert"
            />
          </div>
        </template> -->

        <template #no-data>
          <NullState class="my-4" />
        </template>

        <template #bottom />
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-model="staffDialog"
      width="auto"
    >
      <v-card>
        <v-card-title>
          <v-icon class="me-2"> manage_accounts </v-icon>
          {{ selectedMember.id ? $t('Edit staff member') : $t('New staff member') }}
        </v-card-title>
        <v-card-text
          class="pa-0"
          style="max-height: 70vh; overflow-y: auto"
        >
          <StaffCard
            @change="load"
            :key="selectedMember"
            :member="selectedMember"
            :providers="providers"
            class="mb-3"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
// import ActionMenu from '@/shared/components/ActionMenu.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import useTranslator from '@/shared/composables/useTranslator';
import StaffCard from '@/manager/components/StaffCard.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';

const search = ref(null);
const translator = useTranslator();
const selectedMember = ref({});
const staffDialog = ref(false);
const employees = ref([]);
const providers = ref([]);
const processing = ref(false);
const selectedProviderId = ref(null);
const tableHeaders = computed(() => {
  return [
    {
      title: translator.$t('Name'),
      key: 'name',
    },
    {
      title: translator.$t('Job Title'),
      key: 'title',
    },
    {
      title: translator.$t('Locations'),
      key: 'locations',
    },
    {
      title: translator.$t('Employment status'),
      key: 'employment_status',
    },
    {
      title: translator.$t('CBR enrollment status'),
      key: 'background_check_status',
    },
    {
      title: translator.$t('Start date'),
      key: 'start_date',
      align: 'center',
    },
    {
      title: '',
      value: 'actions',
      align: 'right',
    },
  ];
});

onMounted(load);

watch(selectedProviderId, async () => {
  processing.value = true;
  await load();
});

async function load() {
  const params = {
    provider_id: selectedProviderId.value,
  };
  const providerResp = await Api.manager.provider.index();
  if (providerResp?.status !== 200) return;

  providers.value = providerResp.data;

  const staffResp = await Api.manager.member.index(params);
  if (staffResp?.status !== 200) return;

  employees.value = staffResp.data.map((employee) => ({
    ...employee,
    background_check_status: employee.background_check?.status || '',
    employment_status: employee.employments?.[0]?.end_date ? 'Dismissed' : 'Active',
  }));

  processing.value = false;
  staffDialog.value = false;
}

function newStaffMember() {
  staffDialog.value = true;
  selectedMember.value = {
    employment: {
      start_date: new Date().toISOString().split('T')[0],
    },
  };
}

function editStaffMember(_, { item, _row }) {
  staffDialog.value = true;
  selectedMember.value = { ...item, ...item.employments?.[0] };
}

// TODO: Bring this back when there is time to get it all working properly
// function getEmployeeActions(employee) {
//   if (employee.end_date) {
//     return [{ title: 'Reactivate', event: 'reactivate' }];
//   } else {
//     return [{ title: 'Dismiss', event: 'dismiss' }];
//   }
// }
</script>
