<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-12"
    >
      <div
        ref="progressBar"
        :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
        class="px-4 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!loaded"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>

      <div class="px-4">
        <template v-if="syncedSchema?.data_type == 'Provider'">
          <QuestionSet
            v-model="provider"
            @back="backFromQuestion($event)"
            @next="forwardFromQuestion($event)"
            :processing="processing"
            :questions="validQuestions"
            :readonly="form.locked"
            :schema="syncedSchema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <QuestionSet
            v-model="form"
            @back="backFromQuestion($event)"
            @next="forwardFromQuestion($event)"
            :processing="processing"
            :questions="validQuestions"
            :readonly="form.locked"
            :schema="syncedSchema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <transition-group :name="transitionName">
          <div
            v-for="(question, index) in validVerificationQuestions"
            v-show="section == ['verification', index].join('-')"
            :key="['verification', index].join('-')"
          >
            <FormQuestion
              @back="backFromVerificationQuestion(index)"
              @next="forwardFromVerificationQuestion(index)"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  type: 'Form',
                  id: form.id,
                  tag: question.id,
                  tags: [form.id, question.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                class="mb-6"
              />
            </FormQuestion>
          </div>
        </transition-group>

        <transition-group :name="transitionName">
          <FormQuestion
            v-show="section == 'finish'"
            @back="backFromFinish()"
            @finish="finish"
            key="finish"
            :finish-text="'Done'"
            :forwardable="false"
            :processing="processing"
            section="Finish"
            title="You're all done!"
            finishable
          >
            <p>
              {{ $t(FINISH_MESSAGE) }}
            </p>
          </FormQuestion>
        </transition-group>
      </div>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useStepper from '@/shared/composables/useStepper';
import { useStore } from 'vuex';

const FINISH_MESSAGE =
  "Thanks for completing your application. You'll receive an email shortly to confirm that it has been received.";

const route = useRoute();
const router = useRouter();
const { updateQuery } = useRouterHelper();
const store = useStore();

const attachments = ref([]);
const form = ref(null);
const formSchema = ref(null);
const processing = ref(false);
const progressBar = ref(null);
const provider = ref(null);
const providerSchema = ref(null);

// Despite the very flexible nature of forms/schemas
const syncedSchema = computed(() => {
  if (questions.value[0]?.synced_model === 'Provider') return providerSchema.value;
  else return formSchema.value;
});
const formSchemaId = computed(() => route.params?.schemaId);

const { questions, questionsLoaded, validateAnswers, validQuestions, validVerificationQuestions } =
  useQuestionable({
    ownerDataTypeRef: ref('Schema'),
    ownerIdRef: formSchemaId,
    syncedObjectRef: form,
  });

const { progress, section, stepBack, stepCount, stepForward, stepTotal } = useStepper({
  processing,
  route,
  updateQuery,
});

const loaded = computed(() => {
  return (
    form.value &&
    formSchema.value &&
    provider.value &&
    questionsLoaded.value &&
    questions.value.length > 0
  );
});

watch(
  () => loaded.value,
  async (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      if (route.query.step) stepCount.value = parseInt(route.query.step, 10);
      else stepCount.value = 0;

      if (!stepTotal) await validateAnswers();

      recalculateStepTotal();

      if (route.query.section) {
        section.value = route.query.section;
        progressBar.value?.focus();
        progress.value = (stepCount.value / stepTotal) * 100;
      } else {
        start();
      }
    }
  },
  { immediate: true },
);

watch(
  () => questionsLoaded.value,
  (newVal) => {
    if (newVal) recalculateStepTotal();
  },
);

watch(
  () => route.query.section,
  async (newVal) => {
    section.value = null;
    stepCount.value = parseInt(route.query.step, 10);

    if (!stepTotal.value) await validateAnswers();

    recalculateStepTotal();
    if (newVal) {
      section.value = newVal;
      progressBar.value?.focus();
    } else {
      section.value = 'question-0';
    }
    progress.value = (stepCount.value / stepTotal.value) * 100;
  },
);

onMounted(load);

async function load() {
  formSchema.value = store.state.schemas[route.params.schemaId];

  loadProvider();
  await loadForm();
  void loadAttachments();
}

async function loadForm() {
  const filters = {
    schema_id: route.params.schemaId,
  };

  // Get form
  const { data } = await Api.manager.provider.form.index(route.params.providerId, filters);
  [form.value] = data;

  // If no form - create one
  if (data && !form.value) {
    const response = await Api.manager.provider.form.create(route.params.providerId, {
      schema_id: route.params.schemaId,
    });
    form.value = response.data;
  }
}

async function loadAttachments() {
  const params = {
    owner_type: 'Form',
    owner_id: form.value.id,
  };
  const response = await Api.member.attachment.index(params);
  if (response?.status !== 200) return;
  attachments.value = response.data;
}

function loadProvider() {
  Api.manager.provider.get(route.params.providerId, (response) => {
    provider.value = response.data;
    providerSchema.value = store.state.schemas[provider.value.schema_id];
  });
}

async function maybeSaveProvider() {
  if (syncedSchema.value.data_type !== 'Provider') return;
  await Api.provider.promiseUpdate(route.params.providerId, provider.value);
}

async function saveForm() {
  const { data } = await Api.manager.provider.form.promiseUpdate(
    route.params.providerId,
    form.value.id,
    form.value,
  );
  form.value = data;
}

// Stepping interactions
async function backFromFinish() {
  processing.value = true;
  if (!form.value.locked) {
    await saveForm();
    await maybeSaveProvider();
    await validateAnswers();
  }
  recalculateStepTotal();

  if (validVerificationQuestions.value.length > 0) {
    void backFromVerificationQuestion(validVerificationQuestions.value.length);
  } else {
    void backFromQuestion(validQuestions.value.length);
  }
}

async function backFromQuestion(index) {
  processing.value = true;
  if (!form.value.locked) {
    await saveForm();
    await maybeSaveProvider();
    await validateAnswers();
  }
  recalculateStepTotal();

  if (index - 1 < 0) {
    router.push({ name: 'ProviderIndex' });
  } else {
    stepBack(`question-${index - 1}`);
  }
}

function backFromVerificationQuestion(index) {
  processing.value = true;
  if (index - 1 < 0) {
    void backFromQuestion(validQuestions.value.length);
  } else {
    stepBack(`verification-${index - 1}`);
  }
}

async function finish() {
  processing.value = true;
  if (!form.value.locked) {
    form.value.submitted = true;
    await Api.manager.provider.form.promiseUpdate(
      route.params.providerId,
      form.value.id,
      form.value,
    );
  }
  router.push({ name: 'ProviderIndex' });
}

async function forwardFromQuestion(index) {
  processing.value = true;
  if (!form.value.locked) {
    await saveForm();
    await maybeSaveProvider();
    await validateAnswers();
  }
  recalculateStepTotal();

  if (index + 1 >= validQuestions.value.length) {
    if (validVerificationQuestions.value.length > 0) {
      stepForward('verification-0');
    } else {
      stepForward('finish');
    }
  } else {
    stepForward(`question-${index + 1}`);
  }
}

function forwardFromVerificationQuestion(index) {
  this.processing = true;

  if (index + 1 >= validVerificationQuestions.value.length) {
    stepForward('finish');
  } else {
    stepForward(`verification-${index + 1}`);
  }
}

function recalculateStepTotal() {
  stepTotal.value = validQuestions.value.length + validVerificationQuestions.value.length + 1;
  progress.value = (stepCount.value / stepTotal.value) * 100;
}

function start() {
  processing.value = true;
  if (validQuestions.value.length > 0) {
    stepForward('question-0');
  } else if (validVerificationQuestions.value.length > 0) {
    stepForward('verification-0');
  } else {
    stepForward('finish');
  }
}
</script>
