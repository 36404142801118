<template>
  <div class="w-100pc bg-super-light-blue px-4 oy-scroll my-8">
    <div
      v-if="tourReservation"
      class="mxw-600 mx-auto p-relative pa-8 bg-white border"
    >
      <div class="mb-6 mxw-150 mx-auto">
        <img
          class="w-100pc"
          src="https://res.cloudinary.com/bridgecare/image/upload/v1627844746/media/Checkmark_mbbvjr.svg"
        />
      </div>

      <div class="fw-600 fs-32 mb-4 ta-center">
        {{ $t('Tour confirmed!') }}
      </div>

      <div class="mxw-600 mx-auto ta-left">
        <div class="fs-16">
          {{
            $t(
              'Your tour time has been confirmed. We will send you a reminder the morning of your tour but we encourage you to add it to your calendar.',
            )
          }}
        </div>
      </div>

      <v-divider class="my-6" />

      <div class="mb-4">
        <p class="fs-18 fw-600 mb-2">
          {{ $t('Parent contact information') }}
        </p>

        <div class="fs-16 mb-1">
          {{ $t(tourReservation.meta.member.name) }}
        </div>

        <div class="fs-16">
          {{ $t(tourReservation.meta.member.email) }}
        </div>
      </div>

      <div class="mb-4">
        <p class="fs-18 fw-600 mb-2">
          {{ $t('Children information') }}
        </p>

        <div
          v-for="child in tourReservation.meta.children"
          :key="child.name"
          class="fs-16 mb-1"
        >
          {{ child.name }}, born {{ child.dob }}
        </div>
      </div>

      <div class="mb-4">
        <p class="fs-18 fw-600 mb-2">
          {{ $t('Preferred hours of care') }}
        </p>

        <div class="fs-16 mb-1">
          {{ $t(tourReservation.hours_message) }}
        </div>
      </div>

      <div class="mb-4">
        <p class="fs-18 fw-600 mb-2">
          {{ $t('Message from the family') }}
        </p>

        <div class="fs-16 mb-1">
          {{ $t(tourReservation.other_message) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import { useRoute } from 'vue-router';

const route = useRoute();

const tourReservation = ref(null);

async function confirm() {
  const response = await Api.manager.tourReservation.update(route.params.tourId, {
    confirmed: true,
  });
  tourReservation.value = response.data;
}

async function load() {
  const response = await Api.manager.tourReservation.get(route.params.tourId);
  tourReservation.value = response.data;
}

onMounted(async () => {
  if (route.query.action === 'confirm') {
    await confirm();
  } else {
    await load();
  }
});
</script>
