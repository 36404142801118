<template>
  <div>
    <template v-if="enrollments">
      <div>
        <div class="d-flex justify-center mb-4">
          <v-text-field
            v-model="query"
            :aria-label="t('Search students by name or ID')"
            :placeholder="t('Search students by name or ID')"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
          />
        </div>

        <v-row class="d-flex align-center mb-0">
          <v-col
            class="d-flex align-center"
            cols="12"
            md="6"
          >
            <v-btn
              @click="selectAll()"
              class="me-3"
              color="primary"
              data-cy="select-all-button"
            >
              {{ t(allChecked ? 'Deselect all' : 'Select all') }}
            </v-btn>
            <v-btn
              v-if="store.state.pages.Interested.features.enable_mass_messaging"
              v-show="someChecked"
              @click="$refs.newMessageDialog.open()"
              :loading="processing"
              class="me-3"
              color="primary"
              data-cy="message-button"
              elevation="2"
            >
              {{ t('Message') }}
            </v-btn>

            <ResourceDialog
              @save="sendMassMessage"
              ref="newMessageDialog"
              :processing="processing"
              :save-button-disabled="!massMessage.subject || !massMessage.body"
              save-button-text="Send"
              title="New message"
            >
              <template #form>
                <v-row>
                  <LabeledTextfield
                    v-model="massMessage.subject"
                    message="Subject"
                  />
                  <LabeledTextarea
                    v-model="massMessage.body"
                    message="Body"
                  />
                  <v-col class="fs-16">
                    <p>
                      {{ t('Message will be sent to') }}: <strong>{{ recipientCount }}</strong>
                      {{ t('recipients') }}
                    </p>
                  </v-col>
                </v-row>
              </template>
            </ResourceDialog>

            <v-pagination
              v-if="pages > 1"
              v-model="page"
              :length="pages"
              :total-visible="8"
              class="d-inline-block"
            />
          </v-col>

          <v-col
            class="d-flex justify-end"
            cols="12"
            md="6"
          >
            <FilterMenu
              ref="providerFilter"
              :actions="false"
              :disabled="someChecked"
              :title="
                providers.find((provider) => filters.provider_id == provider.id)?.name || 'Location'
              "
              classes="ms-3"
              width="400"
              borderless
              left
              outlined
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.provider_id"
                  @update:model-value="$refs.providerFilter.close()"
                  :item-title="(item) => item.name + ' (' + item.address1 + ')'"
                  :items="providers"
                  :placeholder="t('Search by name or address')"
                  item-value="id"
                  variant="filled"
                  auto-select-first
                  clearable
                  left
                />
              </template>
            </FilterMenu>

            <FilterMenu
              v-if="filterPrograms.length > 0"
              ref="programFilter"
              :actions="false"
              :disabled="someChecked"
              :title="
                filterPrograms.find((program) => filters.program_id === program.id)?.name ||
                terms.program
              "
              classes="ms-3"
              width="400"
              borderless
              left
              outlined
            >
              <template #card>
                <v-select
                  v-model="filters.program_id"
                  @update:model-value="$refs.programFilter.close()"
                  :item-title="(program) => t(program.name)"
                  :items="filterPrograms"
                  :placeholder="t('Search by name or address')"
                  data-testid="filter-programs"
                  item-value="id"
                  variant="filled"
                  auto-select-first
                  clearable
                  left
                />
              </template>
            </FilterMenu>

            <v-menu location="left">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  :loading="processing"
                  class="bg-white"
                  icon="more_vert"
                  size="small"
                  variant="text"
                />
              </template>

              <v-list>
                <v-list-item @click="download('csv')">
                  <v-list-item-title>{{ t('Export to CSV for Excel') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-card
          class="mb-2"
          border
          flat
          tile
        >
          <v-card-text class="fs-15">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary">
                  <v-icon start> filter_alt </v-icon>
                  <span class="me-1">
                    {{ t('Filtering by:') }}
                  </span>
                  <span class="fw-600">{{ t(filterSummary) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <span>
                  {{ t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                  {{ t('results') }}, {{ t('out of') }} <strong>{{ total }}</strong>
                  {{ t('total results') }}.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <template v-if="allChecked">
          <v-card
            class="bg-primary-extra-light bc-primary-light b-1 mb-2"
            border
            flat
            tile
          >
            <v-card-text class="fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="selectedAllResults">
                    <span class="me-1"
                      >{{ t('Selected all') }} <strong>{{ total }}</strong>
                      {{ t('results') }}.</span
                    >
                    <a
                      @click="selectedAllResults = false"
                      class="underlined c-primary"
                    >
                      <span>
                        {{ t('Clear selection') }}
                      </span>
                    </a>
                  </template>
                  <template v-else>
                    <span class="me-1">
                      {{ t('Selected first') }} <strong>{{ enrollments.length }}</strong>
                      {{ t('results') }}.
                    </span>
                    <a
                      v-if="pages > 1"
                      @click="selectedAllResults = true"
                      class="underlined c-primary"
                      >{{ t('Select all') }} <strong>{{ total }}</strong> {{ t('results') }}.</a
                    >
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="$vuetify.display.smAndUp">
          <v-card
            class="px-2 py-2"
            border
            flat
            tile
          >
            <v-row>
              <SortableTableHeader
                @check="selectAll()"
                @toggle="toggleSort($event)"
                :check-value="allChecked"
                :checkable="true"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="3"
                field="name"
                title="Child's name"
                check-locked
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="1"
                field="dob"
                title="DOB"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="2"
                field="address"
                title="Home address"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="3"
                field="provider"
                title="Location"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                :title="terms.program"
                cols="2"
                field="program"
              />
            </v-row>
          </v-card>
        </template>

        <template v-if="enrollments.length == 0">
          <NullState
            class="my-8"
            hide-new-button
          />
        </template>

        <div
          v-for="enrollment in enrollments"
          @click="display(enrollment)"
          :key="enrollment.id"
          class="mb-2 pointer"
        >
          <EnrollmentRow
            @check="enrollment.checked = !enrollment.checked"
            :display-dot-badge="getEnrolledFormMissing(enrollment)"
            :enrollment="enrollment"
            class="my-2"
          />
        </div>

        <v-pagination
          v-if="pages > 1"
          v-model="page"
          :length="pages"
          :total-visible="8"
          class="mt-4"
        />
      </div>

      <EnrollmentDialog
        @change="load"
        @close="load"
        ref="enrollDialog"
        :subsidy-programs="subsidyPrograms"
      />
    </template>

    <template v-else>
      <v-progress-linear
        class="mt-6"
        indeterminate
      />
    </template>
  </div>
</template>

<script setup>
import { scrollTo } from 'vuetify/lib/composables/goto';
import api from '@/shared/services/all_bright_finder';
import { createDateTimeFileName } from '@/shared/services/date-time-file-name';
import EnrollmentDialog from '@/manager/components/enrollment/EnrollmentDialog.vue';
import EnrollmentRow from '@/manager/components/enrollment/EnrollmentRow.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import useTerms from '@/shared/composables/useTerms';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const store = useStore();
const { terms } = useTerms();

const props = defineProps({
  subsidyProgramId: {
    type: String,
    default: null,
  },
  subsidyPrograms: {
    type: Array,
    default: null,
  },
});

const enrollDialog = ref(null);
const enrollments = ref(null);
const filterPrograms = ref([]);
const filters = ref({
  program_id: route.query.programId,
  provider_id: route.query.providerId,
});
const massMessage = ref({ subject: null, body: null });
const newMessageDialog = ref(null);
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const providers = ref([]);
const query = ref(null);
const queryCount = ref(0);
const selectedAllResults = ref(false);
const sortDirection = ref(null);
const sortField = ref(null);
const subsidyProgram = ref(null);
const total = ref(0);

const allChecked = computed(() => {
  return (
    enrollments.value.length > 0 && checkedEnrollments.value.length === enrollments.value.length
  );
});

const checkedEnrollments = computed(() => {
  return enrollments.value.filter((enrollment) => enrollment.checked);
});

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (filters.value.provider_id && providers.value.length > 0) {
    appliedFilters.push(
      providers.value.find((provider) => provider.id === filters.value.provider_id)?.name,
    );
  }

  if (filters.value.program_id && filterPrograms.value.length > 0) {
    appliedFilters.push(
      filterPrograms.value.find((program) => program.id === filters.value.program_id)?.name,
    );
  }

  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

const someChecked = computed(() => {
  return checkedEnrollments.value.length > 0;
});

const recipientCount = computed(() => {
  if (selectedAllResults.value) return total.value;

  return checkedEnrollments.value.length;
});

watch(page, async () => {
  await load();
  scrollTo(0);
});

watch(query, async (newVal, oldVal) => {
  if (newVal === oldVal) return;

  if (page.value !== 1) {
    page.value = 1;
    return; // The change above will trigger watch that calls load()
  }
  await load();
});

watch(
  () => filters.value.provider_id,
  async (newVal, oldVal) => {
    if (newVal === oldVal) return;

    await loadPrograms(newVal);

    if (
      filters.value.program_id &&
      !filterPrograms.value.find((program) => program.id === filters.value.program_id)
    ) {
      filters.value.program_id = null;
      return; // The change above will trigger watch that calls load()
    }

    if (page.value !== 1) {
      page.value = 1;
      return; // The change above will trigger watch that calls load()
    }
    await load();
  },
  { immediate: true },
);

watch(
  () => filters.value.program_id,
  async (newVal, oldVal) => {
    if (newVal === oldVal) return;

    if (page.value === 1) return await load();

    page.value = 1;
  },
);

watch(
  () => props.subsidyProgramId,
  async (newVal, oldVal) => {
    if (newVal === oldVal) return;

    await load();
  },
);

onMounted(async () => {
  await load();
});

function createMassMessage(enrollmentIds) {
  processing.value = true;
  massMessage.value.enrollment_ids = enrollmentIds;
  api.manager.mass_message.create(
    massMessage.value,
    async () => {
      newMessageDialog.value.close();
      processing.value = false;
      eventBus.chime('Message sent');
      await load();
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors);
    },
  );
}

async function display(enrollment) {
  enrollDialog.value.open(enrollment);
  if (enrollment.seen_by_provider_at) return;

  const params = {
    seen_by_provider: true,
  };
  await api.manager.enrollment.update(enrollment.id, params);
  await load();
}

function download(format) {
  const params = {
    status: ENROLLMENT_STATUSES.ENROLLED,
  };

  if (filters.value.provider_id) params.provider_id = filters.value.provider_id;
  if (filters.value.program_id) params.program_id = filters.value.program_id;
  if (props.subsidyProgramId) params.subsidy_program_id = props.subsidyProgramId;

  const fileName = createDateTimeFileName(
    store.state.pages.Students.title || 'Students',
    store.state.profile.default_locale,
  );

  const url = api.manager.enrollment.downloadUrl(params, fileName, format);
  window.open(url);
}

function getEnrolledFormMissing(enrollment) {
  if (!subsidyProgram.value?.enrolled_form_schema_id) return false;
  return !enrollment.enrolled_form_id;
}

async function load(callback) {
  subsidyProgram.value = props.subsidyPrograms?.find((sp) => sp.id === props.subsidyProgramId);

  await loadProviders();
  if (
    filters.value.provider_id &&
    !providers.value.find((provider) => provider.id === filters.value.provider_id)
  ) {
    filters.value.provider_id = null;
    return; // The change above will trigger watch that calls load()
  }

  const params = {};
  params.page = page.value;
  params.query = query.value;
  params.provider_id = filters.value.provider_id;
  params.program_id = filters.value.program_id;
  params.subsidy_program_id = props.subsidyProgramId;
  queryCount.value += 1;
  const currentQueryCount = queryCount.value;

  if (sortField.value) {
    params.sort_field = sortField.value;
    params.sort_dir = sortDirection.value;
    router.push({ query: params });
  }

  if (selectedAllResults.value) {
    params.page_size = 500;
  }

  params.status = ENROLLMENT_STATUSES.ENROLLED;

  api.manager.enrollment.index(
    params,
    (response) => {
      if (queryCount.value === currentQueryCount) {
        if (callback) {
          callback(response.data);
        } else {
          enrollments.value = response.data.map((enrollment) => ({
            ...enrollment,
            checked: false,
          }));

          page.value = parseInt(response.headers['x-page-count'] || 0, 10);
          total.value = parseInt(response.headers['x-total-count'] || 0, 10);
          processing.value = false;
        }
      }
    },
    (err) => {
      eventBus.error(err);
    },
  );
}

async function loadPrograms(providerId) {
  if (!providerId) {
    filterPrograms.value = [];
    return;
  }

  const programFilters = {
    subsidy_program_id: props.subsidyProgramId,
  };
  const { data } = await api.program.index(providerId, programFilters);
  filterPrograms.value = data;
}

async function loadProviders() {
  const providerFilters = {
    subsidy_program_id: props.subsidyProgramId,
  };
  const { data } = await api.manager.provider.index(providerFilters);
  providers.value = data;
}

function selectAll() {
  const checked = checkedEnrollments.value.length < enrollments.value.length;
  enrollments.value = enrollments.value.map((enrollment) => ({
    ...enrollment,
    checked,
  }));
}

async function sendMassMessage() {
  if (selectedAllResults.value) {
    await load((data) => {
      createMassMessage(data.map((enrollment) => enrollment.id));
    });
  } else {
    createMassMessage(checkedEnrollments.value.map((enrollment) => enrollment.id));
  }
}

async function toggleSort(field) {
  if (sortField.value !== field) {
    sortDirection.value = 'desc';
  } else {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  }
  sortField.value = field;
  await load();
}
</script>
