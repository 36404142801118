<template>
  <div v-if="subsidyPrograms">
    <template v-if="subsidyPrograms.length > 1">
      <v-row class="bg-primary pt-3 mb-2">
        <v-col class="d-flex justify-center py-6">
          <v-select
            v-model="subsidyProgramId"
            :item-title="(subsidyProgram) => t(subsidyProgram.name)"
            :items="subsidyPrograms"
            class="mxw-400"
            density="comfortable"
            item-value="id"
            variant="solo"
            hide-details
          />
        </v-col>
      </v-row>
    </template>

    <v-container class="mxw-1440">
      <template v-if="subsidyPrograms !== null">
        <v-card
          border
          flat
          tile
        >
          <v-card-title role="heading">
            {{ t(store.state.pages.Students.title || 'Students') }}
          </v-card-title>

          <v-divider />

          <v-toolbar
            v-if="store.state.pages.Attendance.enabled || paymentsEnabled"
            elevation="0"
          >
            <v-tabs>
              <v-tab
                key="Enrollment"
                :to="{ name: 'EnrollmentIndex' }"
                class="w-150 c-black fs-16 fw-500 tt-none ls-normal"
              >
                {{ t('All') }}
              </v-tab>

              <v-tab
                v-if="paymentsEnabled"
                key="Payment"
                :to="{ name: 'PaymentIndex' }"
                class="w-150 c-black fs-16 fw-500 tt-none ls-normal"
              >
                {{ t('Payments') }}
              </v-tab>

              <v-tab
                v-if="store.state.pages.Attendance.enabled"
                key="Attendance"
                :to="{ name: 'AttendanceIndex' }"
                class="w-150 c-black fs-16 fw-500 tt-none ls-normal"
              >
                {{ t(terms.attendance) }}
              </v-tab>
            </v-tabs>
          </v-toolbar>

          <v-divider />

          <v-card-text>
            <router-view
              :subsidy-program-id="subsidyProgramId"
              :subsidy-programs="subsidyPrograms"
            />
          </v-card-text>

          <VerticalSpacer :min-height="50" />
        </v-card>
      </template>
    </v-container>
  </div>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import useTerms from '@/shared/composables/useTerms';

const route = useRoute();
const store = useStore();
const { t } = useI18n();
const { terms } = useTerms();

const business = ref(null);
const paymentsEnabled = ref(false);
const subsidyProgramId = ref(null);
const subsidyPrograms = ref(null);

watch(subsidyProgramId, () => {
  store.commit('setFilters', ['subsidy', { subsidyProgramId: subsidyProgramId.value }]);
});

onMounted(async () => {
  if (store.state.features.enable_payments) {
    api.manager.business.get((response) => {
      business.value = response.data;
      paymentsEnabled.value = business.value.enable_payments;
    });
  }

  await loadSubsidyPrograms();
});

async function loadSubsidyPrograms() {
  const { data } = await api.public_api.organization.subsidy_program.index();
  subsidyPrograms.value = data;
  if (store.state.filters.subsidy?.subsidyProgramId) {
    subsidyProgramId.value = store.state.filters.subsidy.subsidyProgramId;
  } else if (route.query.subsidyProgramId) {
    subsidyProgramId.value = route.query.subsidyProgramId;
  } else {
    subsidyProgramId.value = subsidyPrograms.value[0]?.id;
  }
}
</script>

<style scoped>
.v-tab :deep(.v-btn__content) {
  white-space: normal;
  text-align: center;
}
</style>
