<template>
  <v-container class="mxw-1200">
    <v-card
      border
      flat
      tile
    >
      <v-card-title>
        <span>{{ $t('My documents') }}</span>
      </v-card-title>

      <v-divider />

      <v-tabs
        v-model="tab"
        fixed-tabs
      >
        <v-tab class="c-black fs-16 ls-normal tt-none">
          {{ $t('Agreements') }}
        </v-tab>
        <v-tab class="c-black fs-16 ls-normal tt-none">
          {{ $t('Images') }}
        </v-tab>
      </v-tabs>
      <v-divider />

      <v-window v-model="tab">
        <v-window-item>
          <AgreementTable />
        </v-window-item>

        <v-window-item>
          <AssetTable />
        </v-window-item>
      </v-window>

      <VerticalSpacer :min-height="50" />
    </v-card>
  </v-container>
</template>

<script setup>
import AgreementTable from '@/manager/components/AgreementTable.vue';
import AssetTable from '@/manager/components/AssetTable.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const tab = ref(getTab());

function getTab() {
  const tabOptions = {
    agreements: 0,
    assets: 1,
  };

  return tabOptions[route.query.tab] || 0;
}
</script>
