<template>
  <v-alert
    :text="t('Congratulations! This account is up to date.')"
    class="fs-14"
    type="success"
    variant="tonal"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
