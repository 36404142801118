<template>
  <div
    v-if="provider && meta"
    class="w-100pc h-100pc bg-super-light-blue"
  >
    <v-row
      class="w-100pc h-100pc bg-white oy-scroll"
      no-gutters
    >
      <v-col
        v-show="display.mdAndUp || tab === false"
        class="br-1 bc-extra-light-gray bg-super-light-blue"
        cols="12"
        lg="2"
        md="3"
      >
        <div
          class="pa-4 bb-1 bc-very-light-gray"
          data-cy="header"
          no-gutters
        >
          <div class="bg-white rounded elevation-1 pa-3">
            <div
              v-text="provider.name"
              class="fs-16 fw-500"
            />

            <div>
              <span class="fs-14 c-black">
                {{ providerAddress() }}
              </span>
            </div>
          </div>

          <div class="d-flex align-center mt-4">
            <div class="fs-12 fw-600">
              {{ t('Complete your profile') }}
            </div>

            <v-spacer />

            <div class="fs-12 fw-600">{{ totalProgress }}%</div>
          </div>

          <v-progress-linear
            v-model="totalProgress"
            color="primary"
          />

          <div class="d-flex justify-center mt-4">
            <v-btn
              :ripple="false"
              :to="{
                name: 'PublicProviderShow',
                params: {
                  providerId: provider.id,
                },
              }"
              class="bg-white"
              color="primary"
              data-cy="view_public_profile"
              target="_blank"
              variant="outlined"
            >
              {{ t('View public profile') }}
            </v-btn>
          </div>
        </div>

        <div
          class="listbox"
          role="listbox"
        >
          <TabHeader
            v-if="meta.overview.enabled"
            @click="transition('overview')"
            :active="tab === 'overview'"
            data-cy="overview_tab"
            title="Overview"
            hide-icon
          />
          <TabHeader
            v-if="meta.openings.enabled"
            @click="transition('openings')"
            :active="tab === 'openings'"
            :progress="progress.openings"
            data-cy="openings_tab"
            title="Openings"
          />
          <TabHeader
            v-if="meta.photos.enabled"
            @click="transition('photos')"
            :active="tab == 'photos'"
            :progress="progress.photos"
            data-cy="photos_tab"
            title="Photos"
          />
          <TabHeader
            v-if="meta.video.enabled"
            @click="transition('video')"
            :active="tab == 'video'"
            :progress="progress.video"
            data-cy="video_tour_tab"
            title="Video tour"
          />
          <TabHeader
            v-if="meta.profile.enable_description"
            @click="transition('description')"
            :active="tab == 'description'"
            :progress="progress.description"
            data-cy="description_tab"
            title="Description"
          />
          <TabHeader
            @click="transition('contact')"
            :active="tab == 'contact'"
            :progress="progress.contact"
            data-cy="contact_tab"
            title="Contact"
          />
          <TabHeader
            v-if="meta.programs.enabled"
            @click="transition('programs')"
            :active="tab == 'programs'"
            :description="meta.programs.description"
            :progress="progress.programs"
            :title="terms.programs"
            data-cy="programs_tab"
          />
          <TabHeader
            v-if="meta.tours.enabled"
            @click="transition('tours')"
            :active="tab == 'tours'"
            :progress="progress.tours"
            data-cy="tour_requests_tab"
            title="Tour requests"
          />
          <TabHeader
            v-if="meta.enrollment_steps.enabled"
            @click="transition('enrollment_steps')"
            :active="tab == 'enrollment_steps'"
            :progress="progress.enrollment_steps"
            :title="`How to ${terms.enroll.toLowerCase()}`"
            data-cy="how_to_enroll_tab"
          />
          <TabHeader
            v-if="meta.profile.enable_emergency_response"
            @click="transition('emergency')"
            :active="tab == 'emergency'"
            :progress="progress.emergency"
            data-cy="emergency_tab"
            title="COVID-19"
          />
          <TabHeader
            @click="transition('hours')"
            :active="tab == 'hours'"
            :progress="progress.hours"
            data-cy="hours_tab"
            title="Hours"
          />
          <TabHeader
            v-if="meta.profile.enable_payment_details"
            @click="transition('financial')"
            :active="tab == 'financial'"
            :progress="progress.financial"
            data-cy="financial_programs_tab"
            title="Financial programs"
          />
          <TabHeader
            v-if="meta.enrollment.enabled"
            @click="transition('enrollment')"
            :active="tab == 'enrollment'"
            :progress="progress.enrollment"
            data-cy="enrollment_tab"
            title="Enrollment"
          />
          <TabHeader
            v-if="meta.profile.enable_highlights"
            @click="transition('highlights')"
            :active="tab == 'highlights'"
            :progress="progress.highlights"
            data-cy="highlights_tab"
            title="Highlights"
          />
          <TabHeader
            v-if="meta.staff.enabled"
            @click="transition('staff')"
            :active="tab == 'staff'"
            :progress="progress.staff"
            data-cy="staff_tab"
            title="Staff"
          />
          <TabHeader
            v-if="meta.licensing.enabled"
            @click="transition('licensing')"
            :active="tab == 'licensing'"
            data-cy="licensing_tab"
            title="Licensing"
            locked
          />
        </div>
      </v-col>

      <v-col
        v-show="display.mdAndUp || tab != false"
        class="p-relative pa-0"
        cols="12"
        lg="10"
        md="9"
      >
        <div class="h-100pc w-100pc oy-scroll">
          <v-container fluid>
            <div
              ref="tabContainer"
              class="focus-very-visible"
              tabindex="0"
            >
              <transition-group name="slide-left">
                <div
                  v-if="meta.overview.enabled"
                  v-show="tab == 'overview'"
                  key="overview-tab"
                >
                  <ProviderOverview
                    @back="tab = false"
                    @change:publish="changePublished"
                    ref="openings"
                    :processing="processing"
                    :provider="provider"
                  />
                  <TabButtons
                    @next="next()"
                    hide-back
                  />
                </div>

                <div
                  v-if="meta.openings.enabled"
                  v-show="tab == 'openings'"
                  key="openings-tab"
                >
                  <ProgramOpenings
                    @back="tab = false"
                    @change:openings-confirmed="confirmAvailability($event)"
                    @progress="progress.openings = $event"
                    ref="openings"
                    :openings-confirmed-at="provider.openings_confirmed_at"
                    :provider-id="provider.id"
                  />
                  <TabButtons
                    @next="next()"
                    hide-back
                  />
                </div>

                <div
                  v-if="meta.photos.enabled"
                  v-show="tab == 'photos'"
                  key="photos-tab"
                >
                  <AssetManager
                    @back="tab = false"
                    @load="images.load(true)"
                    :description="meta.photos.description"
                    :provider-id="provider.id"
                    category="image"
                    class="mb-4"
                  />
                  <ProviderImages
                    @destroy="destroyAsset"
                    @progress="progress.photos = $event"
                    ref="images"
                    :provider-id="provider.id"
                    class="ma-4"
                    role="manager"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.video.enabled"
                  v-show="tab == 'video'"
                  key="video-tab"
                >
                  <AssetManager
                    @back="tab = false"
                    @load="video.load()"
                    :description="VIRTUAL_TOUR_DESCRIPTION"
                    :provider-id="provider.id"
                    category="video"
                    class="mb-4"
                    title="Virtual tour video"
                  />
                  <VirtualTourGallery
                    @progress="progress.video = $event"
                    ref="video"
                    :provider-id="provider.id"
                    removable
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'description'"
                  key="description-tab"
                >
                  <ProviderDescription
                    @back="tab = false"
                    @progress="progress.description = $event"
                    ref="description"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'contact'"
                  key="contact-tab"
                >
                  <ContactInformation
                    @back="tab = false"
                    @progress="progress.contact = $event"
                    ref="contact"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.tours.enabled"
                  v-show="tab == 'tours'"
                  key="tours-tab"
                >
                  <ProviderTours
                    @back="tab = false"
                    @progress="progress.tours = $event"
                    @refresh="load"
                    ref="tours"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.enrollment_steps.enabled"
                  v-show="tab == 'enrollment_steps'"
                  key="steps-tab"
                >
                  <EnrollmentSteps
                    @back="tab = false"
                    @error="resetRequestProceedFromEnrollmentSteps"
                    @move-forward="onMoveFromEnrollmentSteps"
                    @progress="progress.enrollment_steps = $event"
                    @refresh="load"
                    ref="enrollment_steps"
                    :provider-id="provider.id"
                    :request-proceed-from-enrollment-steps="requestProceedFromEnrollmentSteps"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'emergency'"
                  key="emergency-tab"
                >
                  <EmergencyResponse
                    @back="tab = false"
                    @progress="progress.emergency = $event"
                    @refresh="load()"
                    ref="emergency"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'hours'"
                  key="hours-tab"
                >
                  <ProviderHours
                    v-model="provider"
                    @back="tab = false"
                    @progress="progress.hours = $event"
                    ref="hours"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'financial'"
                  key="financial-tab"
                >
                  <PaymentDetails
                    @back="tab = false"
                    @progress="progress.financial = $event"
                    ref="financial"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.programs.enabled"
                  v-show="tab == 'programs'"
                  key="programs-tab"
                >
                  <ProgramList
                    @back="tab = false"
                    @progress="progress.programs = $event"
                    @refresh="load"
                    ref="programs"
                    :age-groups="
                      store.state.ageGroups.filter((ag) => provider.age_group_ids.includes(ag.id))
                    "
                    :description="meta.programs.description"
                    :provider-id="provider.id"
                    :regular-hours="provider.hours"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.enrollment.enabled"
                  v-show="tab == 'enrollment'"
                  key="enrollment-tab"
                >
                  <EnrollmentCount
                    @back="tab = false"
                    @progress="progress.enrollment = $event"
                    @refresh="load"
                    ref="enrollment"
                    :description="meta.enrollment.description"
                    :provider-id="provider.id"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-show="tab == 'highlights'"
                  key="highlights-tab"
                >
                  <ProviderHighlights
                    @back="tab = false"
                    @progress="progress.highlights = $event"
                    ref="highlights"
                    :provider="provider"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.staff.enabled"
                  v-show="tab == 'staff'"
                  key="staff-tab"
                >
                  <StaffMembers
                    @back="tab = false"
                    @progress="progress.staff = $event"
                    @refresh="load"
                    ref="staff"
                    :provider-id="provider.id"
                  />
                  <TabButtons
                    @back="back()"
                    @next="next()"
                  />
                </div>

                <div
                  v-if="meta.licensing.enabled"
                  v-show="tab == 'licensing'"
                  key="licensing-tab"
                >
                  <ProviderLicensing
                    @back="tab = false"
                    ref="licensing"
                    :provider="provider"
                    locked
                  />
                  <TabButtons
                    @back="back()"
                    hide-next
                  />
                </div>
              </transition-group>
            </div>
          </v-container>
        </div>
      </v-col>
    </v-row>

    <VerticalSpacer :min-height="50" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { useDisplay } from 'vuetify';
import { scrollTo } from 'vuetify/lib/composables/goto';
import { formatAddress } from '@/shared/services/address';
import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';
import useTerms from '@/shared/composables/useTerms';
import Api from '@/manager/services/bright_finder';
import AssetManager from '@/manager/components/provider/AssetManager.vue';
import ContactInformation from '@/shared/components/provider/ContactInformation.vue';
import EmergencyResponse from '@/shared/components/provider/EmergencyResponse.vue';
import EnrollmentCount from '@/shared/components/provider/EnrollmentCount.vue';
import EnrollmentSteps from '@/shared/components/provider/EnrollmentSteps.vue';
import PaymentDetails from '@/shared/components/provider/PaymentDetails.vue';
import ProgramList from '@/manager/components/provider/ProgramList.vue';
import ProgramOpenings from '@/shared/components/provider/ProgramOpenings.vue';
import ProviderDescription from '@/shared/components/provider/ProviderDescription.vue';
import ProviderHighlights from '@/shared/components/provider/ProviderHighlights.vue';
import ProviderHours from '@/shared/components/provider/ProviderHours.vue';
import ProviderImages from '@/shared/components/provider/ProviderImages.vue';
import ProviderLicensing from '@/shared/components/provider/ProviderLicensing.vue';
import ProviderOverview from '@/manager/components/provider/ProviderOverview.vue';
import ProviderTours from '@/manager/components/provider/ProviderTours.vue';
import StaffMembers from '@/shared/components/provider/StaffMembers.vue';
import TabButtons from '@/manager/components/provider/TabButtons.vue';
import TabHeader from '@/shared/components/navigation/TabHeader.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import VirtualTourGallery from '@/shared/components/provider/VirtualTourGallery.vue';

const VIRTUAL_TOUR_DESCRIPTION =
  'Families are interested in seeing your location. ' +
  'Upload a virtual tour video to allow families to check out your space.';

const route = useRoute();
const router = useRouter();
const store = useStore();
const display = useDisplay();
const eventBus = useEventBus();
const { terms } = useTerms();
const { t } = useI18n();

const requestProceedFromEnrollmentSteps = ref(false);
const enabledTabs = ref([]);
const provider = ref(null);
const meta = ref(null);
const processing = ref(false);
const progress = ref({});
const tab = ref(route.query.tab);
const images = ref(null);
const video = ref(null);

const totalProgress = computed(() => {
  const totalItems = Object.keys(progress.value).length;
  const itemsCompleted = Object.values(progress.value).reduce((memo, number) => memo + number);
  return parseInt(itemsCompleted / totalItems, 10);
});

const onMoveFromEnrollmentSteps = () => {
  next({ canProceedFromEnrollmentSteps: true });
};

const resetRequestProceedFromEnrollmentSteps = () => {
  requestProceedFromEnrollmentSteps.value = false;
};

const back = () => {
  const currentIndex = enabledTabs.value.indexOf(tab.value);

  if (currentIndex > 0) {
    return transition(enabledTabs.value[currentIndex - 1]);
  }

  return enabledTabs.value[0];
};

const changePublished = (newValue) => {
  update({ published: newValue });
};

const confirmAvailability = async (message) => {
  const response = await Api.provider.update(provider.value.id, {
    openings_confirmed: true,
  });
  eventBus.chime(message);
  provider.value = response.data;
};

const destroyAsset = (asset) => {
  Api.manager.provider.asset.destroy(provider.value.id, asset.id, () => {
    processing.value = false;
    eventBus.chime('Photo removed.');
    images.value.load();
  });
};

const getAppBarChip = () => {
  if (provider.value.closed_status === 'Open') return { color: 'green', text: 'Open' };
  return { color: 'red', text: provider.value.closed_status };
};

const getEnabledTabs = () => {
  if (!meta.value) return [];

  const tabs = [];
  if (meta.value.overview.enabled) tabs.push('overview');
  if (meta.value.openings.enabled) tabs.push('openings');
  if (meta.value.photos.enabled) tabs.push('photos');
  if (meta.value.video.enabled) tabs.push('video');
  if (meta.value.profile.enable_description) tabs.push('description');
  tabs.push('contact');
  if (meta.value.programs.enabled) tabs.push('programs');
  if (meta.value.tours.enabled) tabs.push('tours');
  if (meta.value.enrollment_steps.enabled) tabs.push('enrollment_steps');
  if (meta.value.profile.enable_emergency_response) tabs.push('emergency');
  tabs.push('hours');
  if (meta.value.profile.enable_payment_details) tabs.push('financial');
  if (meta.value.enrollment.enabled) tabs.push('enrollment');
  if (meta.value.profile.enable_highlights) tabs.push('highlights');
  if (meta.value.staff.enabled) tabs.push('staff');
  if (meta.value.licensing.enabled) tabs.push('licensing');
  return tabs;
};

const getStartingTabProgress = () => {
  const tabProgress = {};
  getEnabledTabs().forEach((t) => {
    tabProgress[t] = 0;
  });
  return tabProgress;
};

const load = () => {
  Api.manager.provider.get(route.params.providerId, (response) => {
    provider.value = response.data;
    meta.value = store.state.schemas[provider.value.schema_id]?.meta;
    progress.value = getStartingTabProgress();
    store.commit('setCurrentPageTitle', provider.value.name);
    store.commit('setAppBarChip', getAppBarChip());
    enabledTabs.value = getEnabledTabs();
    if (!tab.value) {
      if (meta.value?.overview.enabled) {
        tab.value ||= 'overview';
      } else {
        [tab.value] = enabledTabs.value;
      }
    }
  });
};

const next = ({ canProceedFromEnrollmentSteps = false } = {}) => {
  if (tab.value === 'enrollment_steps') {
    requestProceedFromEnrollmentSteps.value = true;

    if (canProceedFromEnrollmentSteps === false) {
      return null;
    }
  }

  const currentIndex = enabledTabs.value.indexOf(tab.value);
  if (currentIndex < enabledTabs.value.length - 1) {
    return transition(enabledTabs.value[currentIndex + 1]);
  }
  return enabledTabs.value[enabledTabs.value.length - 1];
};

const providerAddress = () => {
  if (!provider.value) return null;
  return formatAddress(provider.value.address1, provider.value.city);
};

const save = () => {
  Api.provider.update(
    provider.value.id,
    provider.value,
    () => {
      eventBus.chime('Saved');
      store.commit('setAppBarChip', getAppBarChip());
    },
    (error) => {
      eventBus.chime(error.response.data.errors.join('. '));
    },
  );
};

const transition = (newTab) => {
  tab.value = null;
  router.replace({ query: { tab: newTab } });
  setTimeout(() => {
    tab.value = newTab;
    setTimeout(() => {
      scrollTo(0);
    }, 100);
  }, 400);
};

const update = (newValue) => {
  processing.value = true;
  Api.provider.update(
    provider.value.id,
    newValue,
    (response) => {
      processing.value = false;
      eventBus.chime('Saved');
      provider.value = response.data;
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors.join('. '));
    },
  );
};

onMounted(() => {
  tab.value = tab.value || (display.smAndDown.value ? false : '');
});

onBeforeRouteLeave((_to, _from, next) => {
  eventBus.off('blur');
  next();
});

watch(
  () => route.params.providerId,
  () => {
    provider.value = null;
    eventBus.off('blur');
    eventBus.on('blur', save);
    load();
  },
  { immediate: true },
);

watch(
  () => tab.value,
  (newValue) => {
    if (newValue === 'enrollment_steps') resetRequestProceedFromEnrollmentSteps();
  },
);
</script>
